import { BsWhatsapp } from 'react-icons/bs';


export default function ButtonWhatsapp() {
    return (
        <div>


		<a href="https://wa.me/+525532230784" className="whatsapp" target="__blank"> <i className="icon_whatsapp"><BsWhatsapp/></i></a>
	</div>
    )
}