import GuerreroComponent from '../../components/SchoolProvince/Tlaxcala';
import ButtonWhatsapp from "../../components/ButtonWhatsaapp/ButtonWhatsaapp";
import Footer from '../../components/Footer/Footer';

import Navbar from "../../components/Navbar/Navbar";

export default function Tlaxcala() {
    return (
        <div>
      <Navbar/>

<GuerreroComponent/>
<ButtonWhatsapp/>
<Footer/>

        </div>
    )
}