import GuanajuatoComponent from '../../components/SchoolProvince/Guanajuato';
import ButtonWhatsapp from "../../components/ButtonWhatsaapp/ButtonWhatsaapp";
import Footer from '../../components/Footer/Footer';
import Navbar from "../../components/Navbar/Navbar";


export default function Guanajuato() {
    return (
        <div>
      <Navbar/>

<GuanajuatoComponent/>
<ButtonWhatsapp/>
<Footer/>

        </div>
    )
}