import Footer from '../components/Footer/Footer';
import PrivacyPolicyComponent from '../components/PrivacyPolicy/PrivacyPolicy';
import Navbar from "../components/Navbar/Navbar";


export default function PrivacyPolicy() {
    return (
        <div>
      <Navbar/>
           
            <PrivacyPolicyComponent/>
            <Footer/>
        </div>
    )
}