import ColimaComponent from '../../components/SchoolProvince/Colima';
import ButtonWhatsapp from "../../components/ButtonWhatsaapp/ButtonWhatsaapp";
import Footer from '../../components/Footer/Footer';
import Navbar from "../../components/Navbar/Navbar";


export default function Colima() {
    return (
        <div>
      <Navbar/>

<ColimaComponent/>
<ButtonWhatsapp/>
<Footer/>

        </div>
    )
}